import React, { Fragment } from "react";
import { Background, Parallax } from "react-parallax";
import { Link } from "react-router-dom";

export default function SectionHeader(props) {
  const description = props.description.split("\n");
  return (
    <Parallax strength={props.strench} className="flat-title-page">
      <Background className="custom-bg">
        <div
          style={{
            height: 2000,
            width: 2000,
            backgroundImage: `url('${props.bgImage}')`,
          }}
        />
      </Background>
      <div className="overlay"></div>
      <div className="container wrap-title-page bg-img">
        <div className="title-page">
          <h2 className="title font-weight-700">
            <Link to="#">{props.title}</Link>
          </h2>
          <p className="text">
            {description.length > 1
              ? description.map((item, key) => {
                  return (
                    <Fragment key={key}>
                      {item}
                      <br />
                    </Fragment>
                  );
                })
              : props.description}
          </p>
        </div>
        <div className="breadcrumbs">
          <ul>
            <li>
              <Link to={props.section.url}>{props.section.title}</Link>
            </li>{" "}
            <li>
              <Link to={props.subsection.url}>{props.subsection.title}</Link>
            </li>
          </ul>
        </div>
        <img
          src="/img/title-page/bg-white.png"
          alt="AG REYES"
          className="bg-breadcrumbs"
        />
      </div>
    </Parallax>
  );
}
