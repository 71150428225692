import React from "react";
import styles from "./index.module.scss";
import classNames from "classnames/bind";
const _classNames = classNames.bind(styles);

export default function WhatsappButton() {
  return (
    <a
      href="https://api.whatsapp.com/send?phone=5213317186116&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n."
      className={styles.float}
      target="_blank"
      rel="noreferrer"
    >
      <i className={_classNames("fa", "fa-whatsapp", "my-float")}></i>
    </a>
  );
}
