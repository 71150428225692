import React, { Fragment, useEffect } from "react";
import SectionHeader from "../components/SectionHeader";
import Socials from "../components/Socials";

export default function Equipments() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <Fragment>
      <SectionHeader
        strench={400}
        bgImage={"/img/bg-video.jpg"}
        title={"Equipos"}
        description={
          "Contamos con el servicio de diseño y fabricacion de equipos para produccion de cerveza."
        }
        section={{ url: "#", title: "Inicio" }}
        subsection={{ url: "#", title: "Equipos" }}
      />
      <div className="content-wrap event-v2-page">
        <div className="container">
          <div className="content-page-wrap">
            <div className="flat-events style2">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="event style2 border-f-e6f3ff">
                    <div className="event-img">
                      <a
                        href="https://api.whatsapp.com/send?phone=5213317186116&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n."
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src="/img/servicios/diseno.jpg" alt="AG REYES" />
                      </a>
                    </div>
                    <div className="event-content">
                      <h6 className="title">
                        <a
                          href="https://api.whatsapp.com/send?phone=5213317186116&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n."
                          target="_blank"
                          rel="noreferrer"
                        >
                          Diseño de equipos
                        </a>
                      </h6>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="event style2 border-f-e6f3ff">
                    <div className="event-img">
                      <a
                        href="https://api.whatsapp.com/send?phone=5213317186116&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n."
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src="/img/servicios/fabricacion.jpg"
                          alt="AG REYES"
                        />
                      </a>
                    </div>
                    <div className="event-content">
                      <h6 className="title">
                        <a
                          href="https://api.whatsapp.com/send?phone=5213317186116&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n."
                          target="_blank"
                          rel="noreferrer"
                        >
                          Fabricación de equipos
                        </a>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Socials />
    </Fragment>
  );
}
