import React, { Fragment, useState } from "react";
import { Parallax, Background } from "react-parallax";
import { Link } from "react-router-dom";
import ModalVideo from "react-modal-video";
import "react-modal-video/scss/modal-video.scss";

export default function ParallaxManual(props) {
  const [videoVisibility, setVideoVisibility] = useState(false);
  return (
    <Fragment>
      <Parallax strength={props.strench}>
        <Background className="custom-bg">
          <div
            style={{
              height: 2000,
              width: 2000,
              backgroundImage: `url('${props.bgImage}')`,
            }}
          />
        </Background>
        <section className="flat-video style2 parallax parallax13">
          <div className="overlay"></div>
          <div className="container">
            <div className="video flat-text-center">
              <div className="icon">
                <Link
                  to="#"
                  className="videobox-link"
                  onClick={() => setVideoVisibility(true)}
                >
                  <span className="icon-video"></span>
                  <div className="videobox-animation circle-1"></div>
                  <div className="videobox-animation circle-2"></div>
                  <div className="videobox-animation circle-3"></div>
                </Link>
              </div>
              <div className="content">
                <h2 className="title">
                  <Link to="#">Manual del Cervecero</Link>
                </h2>
                <p className="description">
                  Aprenderás las bases para la fabricación de cerveza a
                  cualquier escala, con más de 15 años de experiencia el
                  Ingeniero Adrián García te compartirá todos los secretos para
                  la elaboración de cerveza.
                </p>
              </div>
            </div>
          </div>
        </section>
      </Parallax>
      <ModalVideo
        channel="youtube"
        autoplay={1}
        isOpen={videoVisibility}
        videoId="aEod-oC_b3U"
        onClose={() => setVideoVisibility(false)}
      />
    </Fragment>
  );
}
