import React, { Fragment, useEffect } from "react";
import SectionHeader from "../components/SectionHeader";
import Socials from "../components/Socials";

export default function Services() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  });
  return (
    <Fragment>
      <SectionHeader
        strench={400}
        bgImage={"/img/bg-video.jpg"}
        title={"Servicios"}
        description={
          "Contamos con varios servicios que ponemos a tu disposición. Nos encantará poder ayudarte en todo lo necesario ya que somos unos amantes de la cerveza."
        }
        section={{ url: "#", title: "Inicio" }}
        subsection={{ url: "#", title: "Sevicios" }}
      />
      <div className="content-wrap event-v2-page">
        <div className="container">
          <div className="content-page-wrap">
            <div className="flat-events style2">
              <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="event style2 border-f-e6f3ff">
                    <div className="event-img">
                      <a
                        href="https://api.whatsapp.com/send?phone=5213317186116&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n."
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src="/img/servicios/recetas.jpg" alt="AG REYES" />
                      </a>
                    </div>
                    <div className="event-content">
                      <h6 className="title">
                        <a
                          href="https://api.whatsapp.com/send?phone=5213317186116&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n."
                          target="_blank"
                          rel="noreferrer"
                        >
                          Diseño de recetas
                        </a>
                      </h6>
                      <p>
                        Diseño y/o modificación de receta de cerveza, de
                        fermentación baja o alta según preferencia, 100%
                        auténtica, ficha técnica y proceso definido para cada
                        tipo de cerveza, reporte de cocimientos, control de
                        fermentación, hoja viajera de producto, (desde la
                        molienda hasta el embotellado). Indicaciones de cuidados
                        y actividades en cada etapa del proceso GARANTÍA DE
                        LIBRES MOVIMIENTOS SIN NINGÚN COSTO EXTRA
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="event style2 border-f-e6f3ff">
                    <div className="event-img">
                      <a
                        href="https://api.whatsapp.com/send?phone=5213317186116&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n."
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src="/img/servicios/coaching.jpg" alt="AG REYES" />
                      </a>
                    </div>
                    <div className="event-content">
                      <h6 className="title">
                        <a
                          href="https://api.whatsapp.com/send?phone=5213317186116&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n."
                          target="_blank"
                          rel="noreferrer"
                        >
                          Coaching
                        </a>
                      </h6>
                      <p>
                        Maestro Cervecero 24/7. A disposición de solución de
                        dudas Seguimiento día a día de la planta Cervecera, Plan
                        de producción mensual Atención personalizada para cada
                        etapa del proceso.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="event style2 border-f-e6f3ff">
                    <div className="event-img">
                      <a
                        href="https://api.whatsapp.com/send?phone=5213317186116&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n."
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src="/img/servicios/visita_planta.jpg"
                          alt="AG REYES"
                        />
                      </a>
                    </div>
                    <div className="event-content">
                      <h6 className="title">
                        <a
                          href="https://api.whatsapp.com/send?phone=5213317186116&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n."
                          target="_blank"
                          rel="noreferrer"
                        >
                          Visita a tu planta
                        </a>
                      </h6>
                      <p>
                        Te gustaría ser más eficiente en tu proceso en cualquier
                        etapa? Ya sea Cocimiento, Embotellado, Carbonatación y
                        planeación.
                        <br />
                        Objetivo: Tener detalle de las cervezas en general, toma
                        de decisiones de manera oportuna para la detección y
                        prevención de problemas.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="event style2 border-f-e6f3ff">
                    <div className="event-img">
                      <a
                        href="https://api.whatsapp.com/send?phone=5213317186116&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n."
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src="/img/servicios/capacitacion.jpg"
                          alt="AG REYES"
                        />
                      </a>
                    </div>
                    <div className="event-content">
                      <h6 className="title">
                        <a
                          href="https://api.whatsapp.com/send?phone=5213317186116&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n."
                          target="_blank"
                          rel="noreferrer"
                        >
                          Capacitación / Asesorías
                        </a>
                      </h6>
                      <p>
                        Capacitación a personal en buenas prácticas, cursos de
                        cerveza, lavado de equipos, reutilizar levadura, etc.{" "}
                        <br />
                        Evita los principales errores en una cervecería como lo
                        son; la sobre carbonatación o la falta de gas en tu
                        cerveza, contaminación, sabores no deseados, etc.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="event style2 border-f-e6f3ff">
                    <div className="event-img">
                      <a
                        href="https://api.whatsapp.com/send?phone=5213317186116&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n."
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src="/img/servicios/maquilas.jpg" alt="AG REYES" />
                      </a>
                    </div>
                    <div className="event-content">
                      <h6 className="title">
                        <a
                          href="https://api.whatsapp.com/send?phone=5213317186116&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n."
                          target="_blank"
                          rel="noreferrer"
                        >
                          Maquilas
                        </a>
                      </h6>
                      <p>
                        ¿Te gustaría tener tu marca de cerveza y aún no tienes
                        equipo?
                        <br />
                        ¡Tenemos la mejor solución para emprender ya!.
                        <br />
                        Desde el cocimiento hasta el embotellado de tu cerveza,
                        puedes estar presente en el proceso y familiarizarte con
                        las buenas prácticas de nuestra cervecería.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="event style2 border-f-e6f3ff">
                    <div className="event-img">
                      <a
                        href="https://api.whatsapp.com/send?phone=5213317186116&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n."
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src="/img/servicios/disenoyfabricacion.jpg"
                          alt="AG REYES"
                        />
                      </a>
                    </div>
                    <div className="event-content">
                      <h6 className="title">
                        <a
                          href="https://api.whatsapp.com/send?phone=5213317186116&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n."
                          target="_blank"
                          rel="noreferrer"
                        >
                          Diseño y fabricación de equipos
                        </a>
                      </h6>
                      <p>
                        Ollas
                        <br />
                        Fermentadores
                        <br />
                        Tanque de Maduración
                        <br />
                        Salas de Cocimientos
                        <br />
                        Y más...
                        <br />
                        Diseños de acuerdo a necesidades y presupuestos
                        <br />
                        Tenemos sociedad con los mejores Paileros del País con
                        más de 15 años en la fabricación de Equipos Cerveceros
                        <br />
                        Excelente precio, pero sobretodo excelente Calidad
                        <br />
                        Envíos a toda la República Mexicana
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Socials />
    </Fragment>
  );
}
