import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import SubHeader from "./subheader";
import Sticky from "react-sticky-el";
import { useLocation } from "react-router-dom";
import useCheckMobileScreen from "../useCheckMobileScreen";

export default function Header() {
  const [showMenu, setShowMenu] = useState(false);
  const location = useLocation();

  const getCurrenUrl = (path, multiple = 0) => {
    if (!multiple) {
      if (location.pathname === path) {
        return "active";
      }
    } else {
      let segments = location.pathname.split("/");
      if (`/${segments[1]}` === path) {
        return "active";
      }
    }
  };

  const isMobile = useCheckMobileScreen();

  return (
    <Fragment>
      <SubHeader />
      <Sticky className={`header-sticky${showMenu ? " show-menu" : ""}`}>
        <div id="header" className="bg-fff">
          <div className="container">
            <div className="header-wrap clearfix">
              <div id="logo">
                <Link to="/">
                  <img
                    src="/img/logo/logo.png"
                    alt="AG REYES"
                    width="243"
                    height="60"
                    data-retina="img/logo/logo@2x.png"
                    data-width="486"
                    data-height="120"
                  />
                </Link>
              </div>
              <div className="nav-wrap flat-text-right style1">
                <nav
                  id={`${isMobile ? "mainnav-mobi" : "main-nav"}`}
                  style={
                    isMobile
                      ? { display: `${showMenu ? "block" : "none"}` }
                      : {}
                  }
                >
                  <ul className="menu">
                    <li className={`${getCurrenUrl("/")}`}>
                      <Link to="/" onClick={() => setShowMenu(false)}>
                        INICIO
                      </Link>
                    </li>

                    <li className={`${getCurrenUrl("/cursos", 1)}`}>
                      <Link to="/cursos" onClick={() => setShowMenu(false)}>
                        CURSOS
                      </Link>
                    </li>

                    <li className={`${getCurrenUrl("/manual")}`}>
                      <Link to="/manual" onClick={() => setShowMenu(false)}>
                        MANUAL
                      </Link>
                    </li>

                    <li className={`${getCurrenUrl("/servicios")}`}>
                      <Link to="/servicios" onClick={() => setShowMenu(false)}>
                        SERVICIOS
                      </Link>
                    </li>

                    <li className={`${getCurrenUrl("/equipos")}`}>
                      <Link to="/equipos" onClick={() => setShowMenu(false)}>
                        EQUIPOS
                      </Link>
                    </li>

                    <li className={`${getCurrenUrl("/calcureyes")}`}>
                      {/* <Link to="/calcureyes" onClick={() => setShowMenu(false)}>
                        CALCUREYES
                      </Link> */}
                      <a
                        href="https://calcureyes.com/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        CALCUREYES
                      </a>
                    </li>

                    <li className={`${getCurrenUrl("/contacto")}`}>
                      <Link to="/contacto" onClick={() => setShowMenu(false)}>
                        CONTACTO
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>

              <div
                className={`mobile-button ${showMenu ? " active" : ""}`}
                onClick={() => setShowMenu(!showMenu)}
              >
                <span></span>
              </div>
            </div>
          </div>
        </div>
      </Sticky>
    </Fragment>
  );
}
