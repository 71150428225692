import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import SectionHeader from "../components/SectionHeader";

export default function Contact() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const initialState = {
    name: "",
    email: "",
    subject: "",
    phone: "",
    message: "",
  };

  const [formData, setFormData] = useState(initialState);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const { name, email, message } = formData;

  const handleChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const checkError = (field) => {
    if (errors[field]) {
      return errors[field].response;
    }
    return "";
  };

  const sendForm = () => {
    setIsSuccess(false);
    setIsLoading(true);
    const json = JSON.stringify(formData);
    axios
      .post("https://api.v1.tecnologiacervecera.com/send_form", json, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const success = response.data.success;
        const data = response.data.data;
        if (!success) {
          setErrors(data.errors);
        } else {
          setErrors({});
          setIsSuccess(true);
          setFormData(initialState);
          console.log("send");
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Fragment>
      <SectionHeader
        strench={400}
        bgImage={"/img/bg-video.jpg"}
        title={"Contacto"}
        description={
          "¿Tienes alguna duda?\n¿Te interesa un curso presencial?\nResolveremos todas tus dudas contactanos...."
        }
        section={{ url: "#", title: "Inicio" }}
        subsection={{ url: "#", title: "Contacto" }}
      />
      <div className="content-wrap blog-single-page">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="content-page-wrap">
                <div className="flat-form-reply">
                  <h3 className="title">DÉJANOS TU COMENTARIO</h3>
                  <form id="form-reply" method="get">
                    <div className="wrap-input-all clearfix">
                      {isSuccess && (
                        <div className="contactSuccess">
                          Contacto enviado con exito.
                        </div>
                      )}
                      <div className="wrap-input one-of-two pd-right-15">
                        <input
                          type="text"
                          name="name"
                          placeholder="Nombre"
                          value={name}
                          onChange={handleChange}
                          required=""
                        />
                        <span className={"contactError"}>
                          {checkError("name")}
                        </span>
                      </div>
                      <div className="wrap-input one-of-two pd-left-15">
                        <input
                          type="email"
                          name="email"
                          placeholder="Email"
                          value={email}
                          onChange={handleChange}
                          required=""
                        />
                        <span className={"contactError"}>
                          {checkError("email")}
                        </span>
                      </div>
                    </div>
                    <div className="wrap-textarea">
                      <textarea
                        name="message"
                        placeholder="Mensaje..."
                        value={message}
                        onChange={handleChange}
                      ></textarea>
                      <span className={"contactError"}>
                        {checkError("message")}
                      </span>
                    </div>
                    <div className="wrap-btn">
                      <button
                        type="button"
                        className="flat-button btn-send"
                        onClick={() => sendForm()}
                        disabled={isLoading}
                      >
                        {!isLoading ? "Enviar Mensaje" : "Enviando..."}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
