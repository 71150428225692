import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import WhatsappButton from "../WhatsappButton";

export default function Footer() {
  return (
    <Fragment>
      <footer id="footer">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="widget-about">
                <div id="logo-ft">
                  <a href="index.html">
                    <img
                      src="/img/logo/logo-ft.png"
                      alt="AG REYES"
                      width="157"
                      height="29"
                      data-retina="/img/logo/logo-ft@2x.png"
                      data-width="157"
                      data-height="29"
                    />
                  </a>
                </div>
                <p className="description">
                  Desarrollo de plantas cerveceras, cursos, asesorías,
                  diseño/venta de equipos, diseño de recetas/procesos y
                  maquilas.
                </p>
                <div className="list-info">
                  <ul>
                    <li className="address">
                      <Link to="#">
                        Av. Chapultepec 15, C.P. 44600 Guadalajara, Jalisco
                      </Link>
                    </li>
                    <li className="phone">
                      <a href="tel:3317186116">33 17 18 61 16</a>
                    </li>
                    <li className="mail">
                      <a href="mailto:contacto@tecnologiacervecera.com">
                        contacto@tecnologiacervecera.com
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="socails">
                  <ul className="list">
                    <li>
                      <a
                        href="https://www.facebook.com/tecnologiacerveceraagreyes/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="fa fa-facebook-official"></span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/tecnologiacerveceraagreyes"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="fa fa-instagram"></span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.youtube.com/channel/UC-m_XJ1pgzrtGj_plF-0eAg"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="fa fa-youtube"></span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://api.whatsapp.com/send/?phone=523317186116&text&app_absent=0"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="fa fa-whatsapp"></span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="widget-link widget-ft">
                <h6 className=" widget-title">Links</h6>
                <div className="list-wrap clearfix">
                  <ul className="one-of-two">
                    <li>
                      <Link to="/">Nosotros</Link>
                    </li>
                    <li>
                      <Link to="/cursos">Cursos</Link>
                    </li>
                    <li>
                      <Link to="/manual">Manual</Link>
                    </li>
                    <li>
                      <Link to="/servicios">Servicios</Link>
                    </li>
                  </ul>
                  <ul className="one-of-two">
                    <li>
                      <Link to="/equipos">Equipos</Link>
                    </li>
                    <li>
                      {/* <Link to="/calcureyes">Calcureyes</Link> */}
                      <a
                        href="https://calcureyes.com/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Calcureyes
                      </a>
                    </li>
                    <li>
                      <Link to="/contacto">Contacto</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* <div className="col-md-4">
            <div className="widget-recent-work widget-ft">
              <h6 className="widget-title">IMÁGENES</h6>
              <div
                className="flat-recentOwl"
                data-column="4"
                data-column2="3"
                data-loop="true"
                data-column3="2"
                data-gap="0"
                data-dots="false"
                data-nav="true"
              >
                <div className="flat-imgbox style1 clearfix owl-carousel">
                  <div className="column">
                    <div className="imgbox style1 transition-vline">
                      <a href="#">
                        <div className="imgbox-img img-vline">
                          <img src="images/footer/1.jpg" alt="AG REYES" />
                          <div className="overlay">
                            <span className="vline"></span>
                            <span className="vline vline-bottom"></span>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="imgbox style1 transition-vline">
                      <a href="#">
                        <div className="imgbox-img img-vline">
                          <img src="images/footer/2.jpg" alt="AG REYES" />
                          <div className="overlay">
                            <span className="vline"></span>
                            <span className="vline vline-bottom"></span>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="column">
                    <div className="imgbox style1 transition-vline">
                      <a href="#">
                        <div className="imgbox-img img-vline">
                          <img src="images/footer/3.jpg" alt="AG REYES" />
                          <div className="overlay">
                            <span className="vline"></span>
                            <span className="vline vline-bottom"></span>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="imgbox style1 transition-vline">
                      <a href="#">
                        <div className="imgbox-img img-vline">
                          <img src="images/footer/4.jpg" alt="AG REYES" />
                          <div className="overlay">
                            <span className="vline"></span>
                            <span className="vline vline-bottom"></span>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="column">
                    <div className="imgbox style1 transition-vline">
                      <a href="#">
                        <div className="imgbox-img img-vline">
                          <img src="images/footer/5.jpg" alt="AG REYES" />
                          <div className="overlay">
                            <span className="vline"></span>
                            <span className="vline vline-bottom"></span>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="imgbox style1 transition-vline">
                      <a href="#">
                        <div className="imgbox-img img-vline">
                          <img src="images/footer/6.jpg" alt="AG REYES" />
                          <div className="overlay">
                            <span className="vline"></span>
                            <span className="vline vline-bottom"></span>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="column">
                    <div className="imgbox style1 transition-vline">
                      <a href="#">
                        <div className="imgbox-img img-vline">
                          <img src="images/footer/7.jpg" alt="AG REYES" />
                          <div className="overlay">
                            <span className="vline"></span>
                            <span className="vline vline-bottom"></span>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="imgbox style1 transition-vline">
                      <a href="#">
                        <div className="imgbox-img img-vline">
                          <img src="images/footer/8.jpg" alt="AG REYES" />
                          <div className="overlay">
                            <span className="vline"></span>
                            <span className="vline vline-bottom"></span>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="column">
                    <div className="imgbox style1 transition-vline">
                      <a href="#">
                        <div className="imgbox-img img-vline">
                          <img src="images/footer/9.jpg" alt="AG REYES" />
                          <div className="overlay">
                            <span className="vline"></span>
                            <span className="vline vline-bottom"></span>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="imgbox style1 transition-vline">
                      <a href="#">
                        <div className="imgbox-img img-vline">
                          <img src="images/footer/10.jpg" alt="AG REYES" />
                          <div className="overlay">
                            <span className="vline"></span>
                            <span className="vline vline-bottom"></span>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="column">
                    <div className="imgbox style1 transition-vline">
                      <a href="#">
                        <div className="imgbox-img img-vline">
                          <img src="images/footer/11.jpg" alt="AG REYES" />
                          <div className="overlay">
                            <span className="vline"></span>
                            <span className="vline vline-bottom"></span>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="imgbox style1 transition-vline">
                      <a href="#">
                        <div className="imgbox-img img-vline">
                          <img src="images/footer/12.jpg" alt="AG REYES" />
                          <div className="overlay">
                            <span className="vline"></span>
                            <span className="vline vline-bottom"></span>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="column">
                    <div className="imgbox style1 transition-vline">
                      <a href="#">
                        <div className="imgbox-img img-vline">
                          <img src="images/footer/13.jpg" alt="AG REYES" />
                          <div className="overlay">
                            <span className="vline"></span>
                            <span className="vline vline-bottom"></span>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="imgbox style1 transition-vline">
                      <a href="#">
                        <div className="imgbox-img img-vline">
                          <img src="images/footer/14.jpg" alt="AG REYES" />
                          <div className="overlay">
                            <span className="vline"></span>
                            <span className="vline vline-bottom"></span>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>

                  <div className="column">
                    <div className="imgbox style1 transition-vline">
                      <a href="#">
                        <div className="imgbox-img img-vline">
                          <img src="images/footer/15.jpg" alt="AG REYES" />
                          <div className="overlay">
                            <span className="vline"></span>
                            <span className="vline vline-bottom"></span>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="imgbox style1 transition-vline">
                      <a href="#">
                        <div className="imgbox-img img-vline">
                          <img src="images/footer/16.jpg" alt="AG REYES" />
                          <div className="overlay">
                            <span className="vline"></span>
                            <span className="vline vline-bottom"></span>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>

                  <div className="column">
                    <div className="imgbox style1 transition-vline">
                      <a href="#">
                        <div className="imgbox-img img-vline">
                          <img src="images/footer/17.jpg" alt="AG REYES" />
                          <div className="overlay">
                            <span className="vline"></span>
                            <span className="vline vline-bottom"></span>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="imgbox style1 transition-vline">
                      <a href="#">
                        <div className="imgbox-img img-vline">
                          <img src="images/footer/18.jpg" alt="AG REYES" />
                          <div className="overlay">
                            <span className="vline"></span>
                            <span className="vline vline-bottom"></span>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          </div>
        </div>
      </footer>
      <div className="bottom bg-15222e">
        <div className="container">
          <div className="row">
            {/* <div className="  col-md-6">
              <div className="copyright flat-text-left">
                <p>
                  © 2021 AG Reyes. Todos los derechos reservados. Diseño por:{" "}
                  <a
                    href="https://www.dscloud.mx"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Dscloud
                  </a>
                  .
                </p>
              </div>
            </div> */}
            <div className="col-md-12">
              <div className="widget flat-text-right no-border">
                <ul className="list">
                  <li>
                    <Link to="aviso">Aviso de privacidad</Link>
                  </li>
                  <li>
                    <Link to="terminos">Terminos y condiciones</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <a id="scroll-top"></a> */}
      <WhatsappButton />
    </Fragment>
  );
}
