import React, { Fragment, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import ParallaxManual from "../components/Parallax/Manual";
import Socials from "../components/Socials";

export default function Manual() {
  const temarioRef = useRef(null);
  const executeScroll = () => {
    temarioRef.current.scrollIntoView({ block: "end", behavior: "smooth" });
  };
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <Fragment>
      <div className="content-wrap  about-us-page">
        <div className="container">
          <div className="content-page-wrap">
            <div className="flat-about-info">
              <div className="info-img border-ra4">
                <Link to="#">
                  <img src="/img/manual.jpg" alt="AG REYES" />
                </Link>
              </div>
              <div className="info-content border-ra4 border-f-e6f3ff">
                <div className="heading">
                  <h3 className="title">
                    <Link to="#">MANUAL DEL CERVECERO</Link>
                  </h3>
                </div>
                <p className="text">
                  En éste libro el Ingeniero te llevará de la mano a diseñar tu
                  estilo de cerveza favorito, con ejercicios resueltos.
                </p>

                <p className="text">
                  Aprenderás las bases para la fabricación de cerveza a
                  cualquier escala.
                </p>

                <p className="text">
                  Con más de 15 años de experiencia el Ingeniero Adrián García
                  te compartirá todos los secretos para la elaboración de
                  cerveza.
                </p>

                <p className="text">
                  {" "}
                  En el Manual encontrarás todos los temas del{" "}
                  <Link to="/cursos">Curso</Link> de Cerveza Básico y Avanzando.
                </p>

                <p className="text">
                  <Link to="#" onClick={executeScroll}>
                    Ver Temario
                  </Link>
                </p>
                <a
                  href="https://api.whatsapp.com/send/?phone=5213317186116&text=Hola%21+Me+interesa+el+Manual+del+Cervecero.&app_absent=0"
                  className="flat-button btn-contact"
                  target="_blank"
                  rel="noreferrer"
                >
                  Comprar manual
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flat-about-detail mb-5" ref={temarioRef}>
        <div className="flat-tabs bg-fff border-ra4 border-f-e6f3ff style2">
          <ul className="tab-title style2 clearfix border-bt-e6f3ff  flat-text-center">
            <li className="item-title  overview">
              <h1 className="inner">Temario</h1>
            </li>
          </ul>
          <div className="item-content">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  GUÍA PARA DISEÑAR UNA CERVEZA PASO A PASO:
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-sm-12 col-md-4">
                  <ul>
                    <li>- Escoger estilo</li>
                    <li>- Decidir % alcohol</li>
                    <li>- Decidir color</li>
                    <li>- Cálculo de malta</li>
                    <li>- Cálculo de agua</li>
                    <li>- Molienda</li>
                    <li>- Maceración</li>
                  </ul>
                </div>
                <div className="col-sm-12 col-md-4">
                  <ul>
                    <li>- Lauter</li>
                    <li>- Kettle</li>
                    <li>- Cálculo de amargor</li>
                    <li>- Whirlpool</li>
                    <li>- Enfriar</li>
                    <li>- Levadura / aereación</li>
                    <li>- Fermentación</li>
                  </ul>
                </div>
                <div className="col-sm-12 col-md-4">
                  <ul>
                    <li>- Maduración</li>
                    <li>- Conteo de células</li>
                    <li>- Filtración (opcional)</li>
                    <li>- Carbonatación</li>
                    <li>- Empacado</li>
                    <li>- Pasteurizar (opcional)</li>
                    <li>- Degustación</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flat-about-detail">
        <div className="flat-tabs bg-fff border-ra4 border-f-e6f3ff style2">
          <ul className="tab-title style2 clearfix border-bt-e6f3ff  flat-text-center">
            <li className="item-title  overview">
              <h1 className="inner">INDICE DEL LIBRO:</h1>
            </li>
          </ul>
          <div className="item-content">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-4">
                  <ul>
                    <li>Introducción</li>
                    <li>La calidad es primero</li>
                    <li>Un poco de historia</li>
                    <li>Las materias primas</li>
                    <li className={"ml-2"}>Agua</li>
                    <li className={"ml-2"}>Malta</li>
                    <li className={"ml-2"}>Lúpulo</li>
                    <li className={"ml-4"}>De aroma</li>
                    <li className={"ml-4"}>De amargor</li>
                    <li className={"ml-4"}>De doble propósito</li>
                    <li className={"ml-2"}>Levadura</li>
                    <li>Diseño de recetas</li>
                    <li>Brújula Cervecera</li>
                    <li>Estilos de Cerveza</li>
                    <li>Cálculo de alcohol</li>
                    <li className={"ml-2"}>Alcohol por volumen (v/v)</li>
                    <li className={"ml-2"}>Alcohol por peso (w/v)</li>
                    <li>Color en Cerveza</li>
                    <li>Cálculo de Color</li>
                    <li>Cálculo de Malta</li>
                    <li>Cálculo de agua</li>
                    <li>Molienda</li>
                    <li>Maceración</li>
                    <li>Importancia de separar mash- lauter</li>
                    <li>Lauter</li>
                    <li>Kettle</li>
                    <li>Cálculo de lúpulo</li>
                    <li>Whirlpool (remolino)</li>
                    <li>Enfriamiento del mosto</li>
                    <li>Fermentación y Aireación</li>
                    <li>Maduración</li>
                    <li>Conteo de células</li>
                    <li>Filtración (opcional)</li>
                    <li>Carbonatación</li>
                    <li>Embotellado</li>
                    <li>Pasteurización (opcional)</li>
                    <li>Análisis sensorial</li>
                    <li>Etiquetado</li>
                    <li>Limpieza y desinfección</li>
                    <li>Ahora sí, a salir a venderla</li>
                    <li>TABLAS</li>
                    <li>SG, ° Plato, ° BX</li>
                    <li>Ajuste de la densidad con respecto a la temperatura</li>
                    <li>Conversión de ° Centígrados a ° Fahrenheit</li>
                    <li>Solubilidad de CO2 en Cerveza. Zahm and Nagel</li>
                    <li>Glosario</li>
                    <li>Bibliografía</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ParallaxManual strench={400} bgImage={"/img/bg-video.jpg"} />
      <div className="container mt-5">
        <div className="row">
          <div className="col-12 d-flex justify-content-center">
            <a
              href="https://api.whatsapp.com/send/?phone=5213317186116&text=Hola%21+Me+interesa+el+Manual+del+Cervecero.&app_absent=0"
              className="flat-button btn-contact"
              target="_blank"
              rel="noreferrer"
            >
              Comprar manual
            </a>
          </div>
        </div>
      </div>
      <Socials />
    </Fragment>
  );
}
