import React, { useState, Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import Slideshow from "../components/Slideshow";
import ParallaxManual from "../components/Parallax/Manual/index";
import Socials from "../components/Socials";
import axios from "axios";
import Testimonials from "../components/Testimonials";

export default function Home() {
  const [slideshows, setSlideshows] = useState([]);
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    axios
      .get(`https://api.v1.tecnologiacervecera.com/slideshows/?limit=5`)
      .then((res) => {
        setSlideshows(res.data.data);
      });

    axios
      .get(`https://api.v1.tecnologiacervecera.com/courses/?limit=3`)
      .then((res) => {
        setCourses(res.data.data);
      });
  }, []);

  return (
    <Fragment>
      <Slideshow content={slideshows} />
      <section className="flat-popular-courses style3">
        <div className="container">
          <div className="flat-title flat-text-center">
            <h2 className="title">Algunos Cursos</h2>
            <p className="description">
              ¿Ya haces cerveza o te gustaría comenzar a producirla? <br />{" "}
              Diseña recetas, fabrica a cualquier escala y crea tus propios
              procedimientos. <br />
              Tenemos el curso ideal para ti....
            </p>
          </div>
          <div className="flat-courses clear-sub-columns clearfix isotope-courses style2">
            {(courses || []).map((item, key) => {
              return (
                <div
                  className={`course style2 one-of-three ${
                    item.category === "Online"
                      ? "text-ea0042 Marketing"
                      : "text-005eea"
                  }`}
                  key={key}
                >
                  <div className="course-border border-f-e6f3ff border-ra4 transition-vline">
                    <div className="course-img img-vline">
                      <Link to={`/cursos/detalles/${item.id}`}>
                        <img src={item.image} alt="AG REYES" />
                      </Link>
                      <Link to={`/cursos/detalles/${item.id}`}>
                        <div className="overlay">
                          <span className="vline"></span>
                          <span className="vline vline-bottom"></span>
                        </div>
                      </Link>
                    </div>
                    <div className="course-content">
                      <div className="text-wrap border-bt-e6f3ff">
                        <h6 className="title">
                          <Link to={`/cursos/detalles/${item.id}`}>
                            {item.title}
                          </Link>
                        </h6>
                        <p className="teacher">
                          <Link to={`/cursos/detalles/${item.id}`}>
                            Fecha: {item.es_date}
                          </Link>
                        </p>
                        <p className="teacher">
                          <Link to={`/cursos/detalles/${item.id}`}>
                            {item.category}
                          </Link>
                        </p>
                        <p className="description">{item.short_description}</p>
                      </div>
                      <div className="wrap-rating-price">
                        <div className="wrap-rating">
                          <span>Duracion: {item.duration} HRS</span>
                        </div>
                        <span className="price">
                          <Link to={`/cursos/detalles/${item.id}`}>
                            Ver más Información
                          </Link>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <ParallaxManual strench={400} bgImage={"/img/bg-video.jpg"} />
      <Testimonials />
      <section>
        <div className="container">
          <div className="flat-title flat-text-center">
            <h2 className="title">TECNOLOGÍA CERVECERA A.G. REYES</h2>
            <p className="description">
              Somos una empresa comprometida en ayudar a mejorar tu proceso de
              elaboración de cerveza, así como la calidad de la misma.
              <br />
              <br />
              Nuestra misión es ayudarte a que obtengas los conocimientos y
              experiencia necesaria para elaborar tu cerveza con una excelente
              calidad, creando también tus propios procedimientos, recetas y
              más.
              <br />
              <br />
              Queremos ahorrarte esa "Prueba y error" e ir a lo seguro, para
              hacer de tu negocio, un negocio escalable.
            </p>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-6 d-flex justify-content-center home_cv_img">
              <img src="/img/cv.jpg" alt="" />
            </div>
            <div className="col-sm-12 col-md-6">
              I.Q y L.Q Adrián García Reyes, egresado de ambas carreras de la
              universidad de Guadalajara (UDEG) en el Centro Universitario de
              Ciencias Exactas e Ingeniería (CUCEI).
              <br />
              <br />
              El ingeniero tiene mucha experiencia desde nano cervecerías hasta
              algunas de las mayores compañías cerveceras artesanales del país.
              <br />
              <br />
              Desempeñó importantes funciones técnicas en las áreas de
              aseguramiento de calidad, investigación y desarrollo, laboratorios
              y servicios técnicos, ingeniería de elaboración, diseño de
              recetas, etc., en las siguientes compañías cerveceras: <br />
              <br />
              <div className="row">
                <div className="col-sm-12 col-md-4">
                  <ul>
                    <li>Cervecería Minerva</li>
                    <li>Cervecería Loba</li>
                    <li>Cervecería Bocanegra</li>
                    <li>Cervecería Mastache</li>
                    <li>Cervecería Primus</li>
                    <li>Cervecería Berber</li>
                    <li>Cervecería la Chingonería</li>
                    <li>Cervecería Alcalde</li>
                    <li>Cervecería Menjunje</li>
                    <li>Cerveza Poe</li>
                  </ul>
                </div>
                <div className="col-sm-12 col-md-4">
                  <ul>
                    <li>Cervecería Tintan</li>
                    <li>Cervecería Unión</li>
                    <li>Cerveza vida latina</li>
                    <li>Cervecería Ánima de Sayula</li>
                    <li>Cervecería Picudo</li>
                    <li>Cervecería Montenegro</li>
                    <li>Cervecería Lázaro</li>
                    <li>Cervecería Tatich</li>
                    <li>Cervecería Fugitiva</li>
                    <li>Cervecería Gran Trigueña</li>
                  </ul>
                </div>
                <div className="col-sm-12 col-md-4">
                  <ul>
                    <li>Cervecería Tierra Bajío</li>
                    <li>Cervecería La Nuestra</li>
                    <li>Cervecería Maltierra</li>
                    <li>Cerveza Morelia</li>
                    <li>Cervecería Antonieta</li>
                    <li>Cervecería Caleta</li>
                    <li>Cerveza Porteña</li>
                    <li>Cervecería 8 maltas</li>
                  </ul>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-sm-12 col-md-12">
                  <p className="description">
                    Algunas de ellas ganadoras de innumerables premios y
                    medallas a nivel nacional e internacional. <br />
                    <br />
                    Además está apoyando el diseño de plantas cerveceras en las
                    ciudades de Cancún, Tabasco, Mazatlán, CDMX, Puebla, Ciudad
                    Guzmán, Cabo San Lucas, Mérida, La Paz B.C.S., Guadalajara,
                    etc.
                    <br />
                    <br />
                    Actualmente maestro cervecero de su propia planta Cervecera
                    ALTA RABIA.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container mt-5">
          <div className="row justify-content-center">
            <div className="col-sm-12 col-md-6">
              <Link to="/cursos">
                <img src="/img/home_map.jpg" alt="" />
              </Link>
            </div>
          </div>
        </div>
      </section>
      <Socials />
    </Fragment>
  );
}
