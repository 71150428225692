import axios from "axios";
import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { Link } from "react-router-dom";

export default function SubHeader() {
  const [header, setHeader] = useState("");

  useEffect(() => {
    axios.get(`https://api.v1.tecnologiacervecera.com/header`).then((res) => {
      setHeader(res.data);
    });
  }, []);

  return (
    <div className="top border-bt-d1d1ff style2">
      <div className="overlay"></div>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="flat-adds flat-text-center">
              <p>
                {header.internal ? (
                  <Link to={`/${header.url}`} className={styles.link}>
                    {header.title}
                  </Link>
                ) : (
                  <a
                    href={header.url}
                    target="_blank"
                    rel="noreferrer"
                    className={styles.link}
                  >
                    {header.title}
                  </a>
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
