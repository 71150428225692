import React from "react";
import Header from "../Header/index";
import Footer from "../Footer";

export default function TemplateApp(props) {
  return (
    <div className="boxed">
      <Header />
      {props.children}
      <Footer />
    </div>
  );
}
