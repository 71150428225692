import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import SectionHeader from "../components/SectionHeader";
import { useParams } from "react-router-dom";
import Testimonials from "../components/Testimonials";

export default function CoursesDetails() {
  const [course, setCourse] = useState({});
  const [relatedCourses, setReleatedCourses] = useState([]);
  const [selectedPanel, setSelectePanel] = useState(0);
  const params = useParams();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    axios
      .get(`https://api.v1.tecnologiacervecera.com/course/${params.id}`)
      .then((res) => {
        setCourse(res.data);
      });
  }, [params]);

  useEffect(() => {
    if (!course.category_id) return;
    axios
      .get(
        `https://api.v1.tecnologiacervecera.com/courses?limit=2&category=${course.category_id}&without=${course.id}`
      )
      .then((res) => {
        setReleatedCourses(res.data);
      });
  }, [course]);

  return (
    <Fragment>
      <SectionHeader
        strench={400}
        bgImage={"/img/bg-video.jpg"}
        title={course.title}
        description={
          "Aprenderás desde cero las bases para la fabricación de tu cerveza."
        }
        section={{ url: "/cursos", title: "Cursos" }}
        subsection={{ url: "#", title: "Detalles" }}
      />
      <div className="content-wrap  courses-single-page">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div className="content-page-wrap">
                <div className="course-single">
                  <div className="img-single">
                    <img src={course.image_details} alt="AG REYES" />
                  </div>
                  <div className="content">
                    <div className="heading">
                      <h6 className="title">{course.title}</h6>
                      <p className="description">
                        {(course.description || "")
                          .split("\n")
                          .map((item, key) => {
                            return (
                              <Fragment key={key}>
                                {item}
                                <br />
                              </Fragment>
                            );
                          })}
                      </p>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <p className="teacher">
                          <Link to="#">Fecha: {course.es_date}</Link>
                        </p>
                        <p className="teacher">
                          <Link to="#">Duración: {course.duration} HRS</Link>
                        </p>
                        <p className="teacher">
                          <Link to="#">{course.category}</Link>
                        </p>
                      </div>

                      <div className="col-md-6">
                        <div className="wrap-cart flat-text-right">
                          <br />
                          {course.internal ? (
                            <Link
                              to={course.btn_url}
                              className="flat-button btn-buy border-ra4 float-right"
                            >
                              {course.btn_label}
                            </Link>
                          ) : (
                            <a
                              href={course.btn_url}
                              target="_blank"
                              rel="noreferrer"
                              className="flat-button btn-buy border-ra4 float-right"
                            >
                              {course.btn_label}
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flat-tabs bg-fff border-ra4 border-f-e6f3ff">
                  <ul className="tab-title style1 clearfix border-bt-e6f3ff">
                    {/* <li
                      className={`item-title  overview ${
                        selectedPanel === 3 ? "active" : ""
                      }`}
                      onClick={() => setSelectePanel(3)}
                    >
                      <span className="inner">Temario</span>
                    </li> */}
                    <li
                      className={`item-title  overview ${
                        selectedPanel === 0 ? "active" : ""
                      }`}
                      onClick={() => setSelectePanel(0)}
                    >
                      <span className="inner">Objetivos</span>
                    </li>
                    <li
                      className={`item-title event ${
                        selectedPanel === 1 ? "active" : ""
                      }`}
                      onClick={() => setSelectePanel(1)}
                    >
                      <span className="inner">A quien va dirigido</span>
                    </li>
                    <li
                      className={`item-title requirements ${
                        selectedPanel === 2 ? "active" : ""
                      }`}
                      onClick={() => setSelectePanel(2)}
                    >
                      <span className="inner">Incluye</span>
                    </li>
                  </ul>
                  {/* <div className="tab-content-wrap">
                    <div
                      className={`tab-content ${
                        selectedPanel === 3 ? "active" : ""
                      }`}
                      style={
                        selectedPanel === 3
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    >
                      <div className="item-content">
                        <div className="text-wrap">
                          <p className="text">
                            GUÍA PARA DISEÑAR UNA CERVEZA PASO A PASO:
                          </p>
                          <ul>
                            <li>Escoger estilo</li>
                            <li>Decidir % alcohol</li>
                            <li>Decidir color</li>
                            <li>Cálculo de malta</li>
                            <li>Cálculo de agua</li>
                            <li>Molienda</li>
                            <li>Maceración</li>
                            <li>Lauter</li>
                            <li>Kettle</li>
                            <li>Cálculo de amargor</li>
                            <li>Whirlpool</li>
                            <li>Enfriar</li>
                            <li>Levadura / aereación</li>
                            <li>Fermentación</li>
                            <li>Maduración</li>
                            <li>Conteo de células</li>
                            <li>Filtración (opcional)</li>
                            <li>Carbonatación</li>
                            <li>Empacado</li>
                            <li>Pasteurizar (opcional)</li>
                            <li>Degustación</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div className="tab-content-wrap">
                    <div
                      className={`tab-content ${
                        selectedPanel === 0 ? "active" : ""
                      }`}
                      style={
                        selectedPanel === 0
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    >
                      <div className="item-content">
                        <div className="text-wrap">
                          {(course.targets || "")
                            .split("\n")
                            .map((item, key) => {
                              return (
                                <p className="text" key={key}>
                                  {item}
                                </p>
                              );
                            })}
                        </div>
                      </div>
                    </div>

                    <div
                      className={`tab-content ${
                        selectedPanel === 1 ? "active" : ""
                      }`}
                      style={
                        selectedPanel === 1
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    >
                      <div className="item-content">
                        <div className="text-wrap">
                          {(course.customers || "")
                            .split("\n")
                            .map((item, key) => {
                              return (
                                <p className="text" key={key}>
                                  {item}
                                </p>
                              );
                            })}
                        </div>
                      </div>
                    </div>

                    <div
                      className={`tab-content ${
                        selectedPanel === 2 ? "active" : ""
                      }`}
                      style={
                        selectedPanel === 2
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    >
                      <div className="item-content">
                        <div className="text-wrap">
                          <ul>
                            {(course.includes || "")
                              .split("\n")
                              .map((item, key) => {
                                return <li key={key}>- {item}</li>;
                              })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-12">
              <div className="sidebar-right">
                <h3>Cursos Relacionados</h3>
                <div className="flat-courses style3 mt-5">
                  {(relatedCourses.data || []).map((related) => {
                    return (
                      <div className="course style3  text-fb868f ">
                        <div className="course-border border-f-e6f3ff border-ra4 transition-vline">
                          <div className="course-img img-vline">
                            <Link to={`/cursos/detalles/${related.id}`}>
                              <img src={related.image} alt="AG REYES" />
                            </Link>
                            <Link to={`/cursos/detalles/${related.id}`}>
                              <div className="overlay">
                                <span className="vline"></span>
                                <span className="vline vline-bottom"></span>
                              </div>
                            </Link>
                          </div>
                          <div className="course-content">
                            <div className="text-wrap border-bt-e6f3ff">
                              <h6 className="title">
                                <Link to={`/cursos/detalles/${related.id}`}>
                                  {related.title}
                                </Link>
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                  {/* <div className="course style3  text-fb868f ">
                    <div className="course-border border-f-e6f3ff border-ra4 transition-vline">
                      <div className="course-img img-vline">
                        <a href="#">
                          <img
                            src="images/sidebar/course-sidaber-3.jpg"
                            alt="AG REYES"
                          />
                        </a>
                        <div className="overlay">
                          <span className="vline"></span>
                          <span className="vline vline-bottom"></span>
                        </div>
                      </div>
                      <div className="course-content">
                        <div className="text-wrap border-bt-e6f3ff">
                          <h6 className="title">
                            <a href="#">Curso de Maquinaria</a>
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Testimonials />
    </Fragment>
  );
}
