import React from "react";
import TinySlider from "tiny-slider-react";

export default function Testimonials() {
  const settings = {
    lazyload: true,
    nav: false,
    loop: true,
    // gutter: 30,
    mouseDrag: true,
    items: 3,
    autoplay: true,
    autoplayButtonOutput: false,
    controls: false,
    responsive: {
      360: {
        items: 1,
      },
      991: {
        items: 2,
      },
    },
  };

  return (
    <section className="flat-testimonials style4">
      <div className="container">
        <div className="flat-title flat-text-center">
          <h2 className="title">Comentarios</h2>
        </div>
        <div className="flat-carousel">
          <div className="wrap-testimonials clearfix owl-carousel">
            <TinySlider settings={settings}>
              <div className="testimonial clearfix v2">
                <div className="wrap-avata-socails flat-text-center">
                  <div className="avata">
                    <a href="https://www.facebook.com/YeLiKiTaS/posts/10162243846295226">
                      <img src="/img/clientes/1.jpg" alt="AG REYES" />
                    </a>
                  </div>
                  <div className="socails">
                    <ul className="list">
                      <li>
                        <a href="https://www.facebook.com/YeLiKiTaS/posts/10162243846295226">
                          <i className="fab fa-facebook"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="wrap-content">
                  <div className="info">
                    <h6 className="name">
                      <a href="https://www.facebook.com/YeLiKiTaS/posts/10162243846295226">
                        Angeles Sanchez
                      </a>
                    </h6>
                  </div>
                  <blockquote>
                    Excelente curso, la atencion de adrian es excelente,
                    resuelve tus dudas y te enseña desde principios basicos
                    hasta procesos mas avanzados que no todos conocen!!!
                  </blockquote>
                </div>
              </div>

              <div className="testimonial clearfix v2">
                <div className="wrap-avata-socails flat-text-center">
                  <div className="avata">
                    <a href="https://www.facebook.com/permalink.php?story_fbid=455745088541301&id=100023174400077">
                      <img src="/img/clientes/2.jpg" alt="AG REYES" />
                    </a>
                  </div>
                  <div className="socails">
                    <ul className="list">
                      <li>
                        <a href="https://www.facebook.com/permalink.php?story_fbid=455745088541301&id=100023174400077">
                          <i className="fab fa-facebook"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="wrap-content">
                  <div className="info">
                    <h6 className="name">
                      <a href="https://www.facebook.com/permalink.php?story_fbid=455745088541301&id=100023174400077">
                        Liz BN
                      </a>
                    </h6>
                  </div>
                  <blockquote>
                    Concluyo el curso online muy bien. Excelente atención y
                    compromiso. Básico-Complejo en cuanto a números, pero
                    entendible siempre. Recomiendo 💯✔
                  </blockquote>
                </div>
              </div>

              <div className="testimonial clearfix v2">
                <div className="wrap-avata-socails flat-text-center">
                  <div className="avata">
                    <a href="https://www.facebook.com/luisfelipe.mendozafuenleal/posts/10219681848820628">
                      <img src="/img/clientes/3.jpg" alt="AG REYES" />
                    </a>
                  </div>
                  <div className="socails">
                    <ul className="list">
                      <li>
                        <a href="https://www.facebook.com/luisfelipe.mendozafuenleal/posts/10219681848820628">
                          <i className="fab fa-facebook"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="wrap-content">
                  <div className="info">
                    <h6 className="name">
                      <a href="https://www.facebook.com/luisfelipe.mendozafuenleal/posts/10219681848820628">
                        Luis Felipe Mendoza Fuenleal
                      </a>
                    </h6>
                  </div>
                  <blockquote>
                    buena tarde, quiero recomendar a esta empresa, yo tomé un
                    curso de cervecería, muy recomendable, muy profesional y un
                    alto dominio de la materia del ing. Q. Adrián
                  </blockquote>
                </div>
              </div>

              <div className="testimonial clearfix v2">
                <div className="wrap-avata-socails flat-text-center">
                  <div className="avata">
                    <a href="https://www.facebook.com/jesus.saucedoarmenta.7/posts/1333756120120706">
                      <img src="/img/clientes/4.jpg" alt="AG REYES" />
                    </a>
                  </div>
                  <div className="socails">
                    <ul className="list">
                      <li>
                        <a href="https://www.facebook.com/jesus.saucedoarmenta.7/posts/1333756120120706">
                          <i className="fab fa-facebook"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="wrap-content">
                  <div className="info">
                    <h6 className="name">
                      <a href="https://www.facebook.com/jesus.saucedoarmenta.7/posts/1333756120120706">
                        Carlos Jesús Saucedo Armenta
                      </a>
                    </h6>
                  </div>
                  <blockquote>
                    Fue de las mejores oportunidades que pude tomar
                  </blockquote>
                </div>
              </div>

              <div className="testimonial clearfix v2">
                <div className="wrap-avata-socails flat-text-center">
                  <div className="avata">
                    <a href="https://www.facebook.com/vargasportillo/posts/10215005678721493">
                      <img src="/img/clientes/5.jpg" alt="AG REYES" />
                    </a>
                  </div>
                  <div className="socails">
                    <ul className="list">
                      <li>
                        <a href="https://www.facebook.com/vargasportillo/posts/10215005678721493">
                          <i className="fab fa-facebook"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="wrap-content">
                  <div className="info">
                    <h6 className="name">
                      <a href="https://www.facebook.com/vargasportillo/posts/10215005678721493">
                        Hector Manuel Vargas Portillo
                      </a>
                    </h6>
                  </div>
                  <blockquote>
                    Excelente, el curso esta muy completo el ambiente y la forma
                    en que se realiza te llevan a conocer desde las bases hasta
                    la realización de la cerveza muy recomendable para todos
                    aquellos que ya estan o empiezan en este mundo de la cerveza
                    artesanal.
                  </blockquote>
                </div>
              </div>

              <div className="testimonial clearfix v2">
                <div className="wrap-avata-socails flat-text-center">
                  <div className="avata">
                    <a href="https://www.facebook.com/permalink.php?story_fbid=587961705050414&id=100015097684703">
                      <img src="/img/clientes/6.jpg" alt="AG REYES" />
                    </a>
                  </div>
                  <div className="socails">
                    <ul className="list">
                      <li>
                        <a href="https://www.facebook.com/permalink.php?story_fbid=587961705050414&id=100015097684703">
                          <i className="fab fa-facebook"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="wrap-content">
                  <div className="info">
                    <h6 className="name">
                      <a href="https://www.facebook.com/permalink.php?story_fbid=587961705050414&id=100015097684703">
                        Jose Contreras
                      </a>
                    </h6>
                  </div>
                  <blockquote>
                    Recomiendo ampliamente el curso online, es bastante completo
                    y la información muy digerible y fácil de comprender, además
                    q la atención del ingeniero Adrián en cada duda es
                    excelente, muy feliz de poder tomar este curso 👌😃 y si lo
                    toman seguro no se arrepentirán 👍
                  </blockquote>
                </div>
              </div>
            </TinySlider>
          </div>
        </div>
      </div>
    </section>
  );
}
