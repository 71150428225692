import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Pagination from "../components/Pagination";
import SectionHeader from "../components/SectionHeader";
import chunk from "lodash/chunk";

export default function Courses() {
  const limit = 9;
  const paginationInitState = {
    count: 0,
    pageCount: 0,
  };

  const [typeCourse, setTypeCourse] = useState(0);
  const [courses, setCourses] = useState([]);
  const [page, setPage] = useState(paginationInitState);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    axios
      .get(
        `https://api.v1.tecnologiacervecera.com/courses/?field=date&offset=${offset}&limit=${limit}&order=desc${
          typeCourse > 0 ? `&category=${typeCourse}` : ""
        }`
      )
      .then((res) => {
        const pageCount = Math.ceil(res.data.count / limit);
        setPage({
          count: res.data.count,
          pageCount: pageCount,
        });
        setCourses(res.data.data);
      });
  }, [offset, typeCourse]);

  let rows = [];

  return (
    <Fragment>
      <SectionHeader
        strench={400}
        bgImage={"/img/bg-video.jpg"}
        title={"Cursos"}
        description={
          "¿Ya haces cerveza o te gustaría comenzar a producirla?\nDiseña recetas, fabrica a cualquier escala y crea tus propios procedimientos.\nTenemos el curso ideal para ti ...."
        }
        section={{ url: "/", title: "Inicio" }}
        subsection={{ url: "#", title: "Cursos" }}
      />
      <div className="content-wrap  courses-grid-v2-page">
        <div className="container">
          <div className="content-page-wrap">
            <ul className="flat-filter-isotype style1 text-center ">
              <li className={`${typeCourse === 0 ? "active" : ""}`}>
                `
                <Link
                  to="#"
                  onClick={() => {
                    setTypeCourse(0);
                  }}
                >
                  TODOS
                </Link>
              </li>
              <li className={`${typeCourse === 1 ? "active" : ""}`}>
                `
                <Link
                  to="#"
                  onClick={() => {
                    setTypeCourse(1);
                  }}
                >
                  PRESENCIALES
                </Link>
              </li>
              <li className={`${typeCourse === 2 ? "active" : ""}`}>
                `
                <Link
                  to="#"
                  onClick={() => {
                    setTypeCourse(2);
                  }}
                >
                  ONLINE
                </Link>
              </li>
            </ul>

            {(courses || []).forEach((item, key) => {
              rows.push(
                <div
                  className={`course style2 one-of-three ${
                    item.category === "Online"
                      ? "text-ea0042 Marketing"
                      : "text-005eea"
                  }`}
                  key={key}
                >
                  <div className="course-border border-f-e6f3ff border-ra4 transition-vline">
                    <div className="course-img img-vline">
                      <Link to={`/cursos/detalles/${item.id}`}>
                        <img src={item.image} alt="AG REYES" />
                      </Link>
                      <Link to={`/cursos/detalles/${item.id}`}>
                        <div className="overlay">
                          <span className="vline"></span>
                          <span className="vline vline-bottom"></span>
                        </div>
                      </Link>
                    </div>
                    <div className="course-content">
                      <div className="text-wrap border-bt-e6f3ff">
                        <h6 className="title">
                          <Link to={`/cursos/detalles/${item.id}`}>
                            {item.title}
                          </Link>
                        </h6>
                        <p className="teacher">
                          <Link to={`/cursos/detalles/${item.id}`}>
                            Fecha: {item.es_date}
                          </Link>
                        </p>
                        <p className="teacher">
                          <Link to={`/cursos/detalles/${item.id}`}>
                            {item.category}
                          </Link>
                        </p>
                        <p className="description">{item.short_description}</p>
                      </div>
                      <div className="wrap-rating-price">
                        <div className="wrap-rating">
                          <span>Duracion: {item.duration} HRS</span>
                        </div>
                        <span className="price">
                          <Link to={`/cursos/detalles/${item.id}`}>
                            Ver más Información
                          </Link>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            {chunk(rows, 3).map(function (group, key) {
              return (
                <div
                  className="flat-courses clear-sub-columns clearfix isotope-courses style2"
                  key={key}
                >
                  {group}
                </div>
              );
            })}
          </div>

          <Pagination
            pageCount={page.pageCount}
            onPageChange={setOffset}
            data={courses}
          />
        </div>
      </div>
    </Fragment>
  );
}
