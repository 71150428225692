import React from "react";

export default function Socials() {
  return (
    <section className="flat-feature style2">
      <div className="container">
        <div className="row flat-iconbox style1">
          <div className="col-lg-3 col-md-3">
            <div className="iconbox style1 left">
              <a
                href="https://www.facebook.com/tecnologiacerveceraagreyes"
                target="_blank"
                rel="noreferrer"
              >
                <div className="iconbox-icon">
                  <span className="fa fa-facebook-official"></span>
                </div>
              </a>
              <div className="iconbox-content">
                <h5 className="title">
                  {" "}
                  <a
                    href="https://www.facebook.com/tecnologiacerveceraagreyes"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Facebook{" "}
                  </a>
                </h5>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-3">
            <div className="iconbox style1 left">
              <a
                href="https://www.instagram.com/tecnologiacerveceraagreyes"
                target="_blank"
                rel="noreferrer"
              >
                <div className="iconbox-icon">
                  <span className="fa fa-instagram"></span>
                </div>
              </a>
              <div className="iconbox-content">
                <h5 className="title">
                  {" "}
                  <a
                    href="https://www.instagram.com/tecnologiacerveceraagreyes"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Instagram{" "}
                  </a>
                </h5>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-3">
            <div className="iconbox style1 left">
              <a
                href="https://www.youtube.com/channel/UC-m_XJ1pgzrtGj_plF-0eAg"
                target="_blank"
                rel="noreferrer"
              >
                <div className="iconbox-icon">
                  <span className="fa fa-youtube"></span>
                </div>
              </a>
              <div className="iconbox-content">
                <h5 className="title">
                  {" "}
                  <a
                    href="https://www.youtube.com/channel/UC-m_XJ1pgzrtGj_plF-0eAg"
                    target="_blank"
                    rel="noreferrer"
                  >
                    YouTube{" "}
                  </a>
                </h5>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-3">
            <div className="iconbox style1 left">
              <a
                href="https://api.whatsapp.com/send?phone=5213317186116&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n"
                target="_blank"
                rel="noreferrer"
              >
                <div className="iconbox-icon">
                  <span className="fa fa-whatsapp"></span>
                </div>
              </a>
              <div className="iconbox-content">
                <h5 className="title">
                  {" "}
                  <a
                    href="https://api.whatsapp.com/send?phone=5213317186116&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n"
                    target="_blank"
                    rel="noreferrer"
                  >
                    WhatsApp{" "}
                  </a>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
