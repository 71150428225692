import React from "react";
import Slider from "react-animated-slider";
import customCss from "./index.module.scss";
import { Link } from "react-router-dom";

export default function Slideshow(props) {
  return (
    <Slider classNames={customCss} autoplay={7000}>
      {(props.content || []).map((item, index) => (
        <div
          key={index}
          className={customCss.sliderContent}
          style={{ background: `url('${item.image}') no-repeat center center` }}
        >
          <div className={customCss.inner}>
            <h1>
              {item.title.split("\n").map((item, key) => {
                return (
                  <span key={key}>
                    {item}
                    <br />
                  </span>
                );
              })}
            </h1>
            <p>
              {item.description.split("\n").map((item, key) => {
                return (
                  <span key={key}>
                    {item}
                    <br />
                  </span>
                );
              })}
            </p>
            {item.btn_label &&
              item.btn_url &&
              (item.internal ? (
                <Link to={item.btn_url}>
                  <button>{item.btn_label}</button>
                </Link>
              ) : (
                <a href={item.btn_url} rel="noreferrer" target="_blank">
                  <button>{item.btn_label}</button>
                </a>
              ))}
          </div>
        </div>
      ))}
    </Slider>
  );
}
