import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/style.css";
import "./styles/responsive.css";
import "./styles/shortcode.css";
import "./styles/liblary.css";
import "./styles/custom.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import TemplateApp from "./components/TemplateApp/TemplateApp";
import Home from "./sections/Home";
import Courses from "./sections/Courses";
import Manual from "./sections/Manual";
import Services from "./sections/Services";
import Equipments from "./sections/Equipments";
import Contact from "./sections/Contact";
import CoursesDetails from "./sections/CoursesDetails";
// import Calculator from "./sections/Calculator";

function App() {
  return (
    <Router>
      <TemplateApp>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/cursos/detalles/:id" component={CoursesDetails} />
          <Route exact path="/cursos" component={Courses} />
          <Route exact path="/manual" component={Manual} />
          <Route exact path="/servicios" component={Services} />
          <Route exact path="/equipos" component={Equipments} />
          {/* <Route exact path="/calcureyes" component={Calculator} /> */}
          <Route exact path="/contacto" component={Contact} />
        </Switch>
      </TemplateApp>
    </Router>
  );
}

export default App;
